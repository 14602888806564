import React, { Component } from 'react';
import TransitionHeader from './../TransitionHeader';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import MinySubHeading from '../MinySubHeading';
import ContactAction from './../ContactAction';
import FooterActionNavigation from './../FooterActionNavigation';
import NumberItem from './../NumberItem';
import Marquee from "react-fast-marquee";
import './CaseStudiesContent.scss';

// Articles data
import articles from '../../articles/articles.js';

export default class CaseStudiesContent extends Component {
    constructor(props) {
        super(props);

        // State to track the selected category and article visibility
        this.state = {
            selectedCategory: '', // No category selected initially
            activeButton: '', // Track the active button
            visibleArticles: new Set(),  // Track visible articles for fade-in effect
        };

        this.articleRefs = {}; // Refs to track each article element
        articles.forEach(article => {
            this.articleRefs[article.id] = React.createRef();
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const header = document.getElementById('header-view');
        const hamburger = document.getElementById('menu-button');

        header.classList.remove('header-view-show');
        hamburger.classList.remove('is-active');

        // Initialize Intersection Observer
        this.initializeObserver();
    }

    componentWillUnmount() {
        // Disconnect the observer when the component unmounts to avoid memory leaks
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    initializeObserver = () => {
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px',
            threshold: 0.1 // Trigger when 10% of the element is visible
        };

        this.observer = new IntersectionObserver(this.handleIntersection, options);

        // Observe each article
        articles.forEach(article => {
            if (this.articleRefs[article.id].current) {
                this.observer.observe(this.articleRefs[article.id].current);
            }
        });
    }

    handleIntersection = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Add the article ID to the visibleArticles set with a delay to trigger sequential fade-in
                const delay = Array.from(this.state.visibleArticles).length * 100; // Delay increases for each visible article
                setTimeout(() => {
                    this.setState(prevState => ({
                        visibleArticles: new Set(prevState.visibleArticles).add(entry.target.dataset.id)
                    }));
                }, delay);
            }
        });
    }

    // Handler to update the selected category and active button
    handleCategoryClick = (category) => {
        this.setState({
            selectedCategory: category,
            activeButton: category, // Set the clicked button as active
            visibleArticles: new Set() // Reset visible articles for fade-in effect
        }, this.initializeObserver);
    }

    // Handler to reset the category filter, show all articles, and remove active button
    handleResetClick = () => {
        this.setState({
            selectedCategory: '',
            activeButton: '', // Reset active button
            visibleArticles: new Set() // Reset visible articles for fade-in effect
        }, this.initializeObserver);
    }

    render() {
        const { selectedCategory, activeButton, visibleArticles } = this.state;

        return (
            <div className="case-studies-content-wrapper animate-in">
                <span className='miny-sub-heading-wrapper initial-transition-header animate-in animate-show'>
                    <span></span><span>Case Studies</span>
                </span>
                <div className="case-studie-text-wrapper">
                    <p>At Layercayk, we <strong>specialize in crafting high-impact websites</strong> and <strong>innovative web applications</strong> that not only look great but are designed to boost user engagement and deliver measurable returns on investment (ROI).<br/><br/> Our expertise extends beyond just building websites; we <strong>integrate cutting-edge digital marketing strategies</strong> to enhance your online presence and drive business growth.</p>
                </div>
                <section className="filters">
                    <p>Filter our work:</p>
                    <Marquee pauseOnHover={true}>
                        {/* Render filter buttons */}
                        {['Telecommunication', 'Community', 'Education', 'Mineral Resources', 'SASS', 'Fitness', 'Health', 'Wedding', 'Start up', 'Ecommerce', 'Realestate', 'Home Builders', 'Crypto', 'Non Profit'].map(category => (
                            <button 
                                key={category} 
                                onClick={() => this.handleCategoryClick(category)}
                                className={activeButton === category ? 'active' : ''}
                            >
                                {category}
                            </button>
                        ))}
                        <button 
                            onClick={this.handleResetClick} 
                            className={`reset ${activeButton === '' ? 'active' : ''}`}
                        >
                            Reset
                        </button>
                    </Marquee>
                </section>
                <div className="cases-wrapper animate-in">
                    <NumberItem
                        number="01"
                        left="0"
                        margin="0px 0px 0px 55px"
                        top="85px"
                    />
                    {
                        articles.map(article => {
                            // Check if the article matches the selected category
                            const matchesCategory = selectedCategory === '' || article.category === selectedCategory;
                            const isVisible = visibleArticles.has(article.id.toString());

                            return (
                                <div
                                    data-attr={article.category}
                                    key={article.id}
                                    ref={this.articleRefs[article.id]}
                                    data-id={article.id}
                                    style={{
                                        backgroundImage: `url('${article.image_main}')`,
                                        display: matchesCategory ? 'block' : 'none',
                                        opacity: isVisible ? 1 : 0, // Fade in effect
                                        transform: isVisible ? 'translateY(0)' : 'translateY(15px)', // Move from top 15px to 0
                                        transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // Smooth transition
                                        transitionDelay: `${Array.from(visibleArticles).length * 0.1}s` // Delay for sequential effect
                                    }}
                                    className={`article-item ${isVisible ? 'fade-in' : ''}`}
                                >
                                    <Link to={`/work/${article.id}`} title={article.name} aria-label={article.exerpt}>
                                        <span className="filter"></span>
                                        <span className="init-filter"></span>
                                        <span className="filter-hover"></span>

                                        <span>
                                            <p className="date">{article.date}</p>
                                            <h5>{article.name}</h5>
                                            {article.collaboration && <p className="collab">{article.collaboration}</p>}
                                            <span><ReactMarkdown source={article.exerpt} /></span>
                                        </span>
                                    </Link>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}
